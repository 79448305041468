import { render, staticRenderFns } from "./Brand.vue?vue&type=template&id=6d454e68&scoped=true&"
import script from "./Brand.vue?vue&type=script&lang=js&"
export * from "./Brand.vue?vue&type=script&lang=js&"
import style0 from "./Brand.vue?vue&type=style&index=0&id=6d454e68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d454e68",
  null
  
)

export default component.exports